/* eslint-disable @typescript-eslint/no-explicit-any */
import {
	availableLanguages,
	defaultLanguage,
	fetchLanguageMessages,
	setLanguage,
	useAppDispatch,
	useAppSelector
} from '@hospy/store';
import { useEffect, useMemo, useState } from 'react';
import { IntlProvider } from 'react-intl';
import { useLocation } from 'react-router-dom';
import { LoadingAppView } from '../loading-app-view';

interface ITransalateProvider {
	children: JSX.Element;
	defaultMessagesFunction: Promise<any>;
	app: string;
}

function useQuery() {
	const { search } = useLocation();
	return useMemo(() => new URLSearchParams(search), [search]);
}

const getStoredLanguage = () => {
	const localData = sessionStorage.getItem('language');
	return localData || '';
};

export const TranslateProvider = ({
	children,
	defaultMessagesFunction,
	app
}: ITransalateProvider) => {
	const [defaultMessage, setDefaultMessage] = useState<any>();
	const { language, messages } = useAppSelector(({ language }) => language);
	const dispatch = useAppDispatch();
	const query = useQuery();

	const getQueryLanguage = () => {
		const queryData = query.get('language');
		return queryData;
	};

	useEffect(() => {
		(async () => {
			const gottenMessages = (await defaultMessagesFunction).default;
			const libsMessages = (
				await import('../../../../../languages/compiled-lang/es.json')
			).default;
			setDefaultMessage({ ...gottenMessages, ...libsMessages });
		})();
		let desiredLanguage = getQueryLanguage();
		if (!desiredLanguage) {
			desiredLanguage = getStoredLanguage();
		}
		if (!desiredLanguage) {
			desiredLanguage = navigator.language?.split('-')[0];
		}
		if (
			!availableLanguages.find(
				(language) => language.code === desiredLanguage
			)
		)
			desiredLanguage = defaultLanguage;

		dispatch(setLanguage(desiredLanguage));
	}, []);

	useEffect(() => {
		if (language && language !== '') {
			dispatch(fetchLanguageMessages({ app }));
		}
	}, [language]);

	if (!defaultMessage) return <LoadingAppView hideLogo={true} />;

	return (
		<IntlProvider
			locale={language || defaultLanguage}
			defaultLocale={defaultLanguage}
			messages={(messages as any) || defaultMessage}
		>
			{children}
		</IntlProvider>
	);
};
